<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  meta: {
    type: Object,
    required: true
  }
});
const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta({ ...props.meta });
</script>
<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.blog')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>
    <div class="my-3 px-4">
      <div class="flex flex-col mt-3 cursor-pointer hover:bg-gray-100 hover:shadow-md transition-all">
        <small class="flex self-start bg-gray-200 rounded-lg p-1 text-gray-600"> {{ data.reading_times }} mins read
        </small>
        <h2 class="text-gray-900 text-xl font-medium my-2">{{ data.title }}</h2>
        <img class="w-full object-cover rounded-md h-80" :src="data.picture" :alt="data.title">

        <p class="flex items-center gap-3 text-gray-400 mt-3">
          <small class="me-1">
            <i class="fa-solid fa-calendar me-1"></i>
            {{ new Date(data.created_at).toLocaleDateString() }}
          </small>
          <small class="me-1">
            <i class="fa-solid fa-comments me-1"></i>
            Comments ({{ data.comments_count || 0 }})
          </small>
        </p>
        <div v-html="data.contents"></div>
      </div>

    </div>

    <template #mobile-footer></template>
  </NuxtLayout>
</template>